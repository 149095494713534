<template>
  <router-link
    :to="tab.to"
    v-slot="{ isActive, href, navigate }"
  >
    <a
      :href="href"
      @click="navigate"
      class="c-d-flex ui-flex-row ui-items-center ui-justify-center ui-transition ui-duration-300 ui-px-12 md:ui-px-8 lg:ui-px-12"
      :class="{ active: isActive }"
    >
      <icon
        v-if="tab.icon"
        :name="tab.icon"
        :color="isActive ? 'var(--c-color-primary-300)' : 'var(--c-color-gray-300)'"
        class="ui-mr-2"
      />
      <c-icon
        v-else-if="tab.cicon"
        :name="tab.cicon"
        :color="isActive ? 'var(--c-color-primary-300)' : 'var(--c-color-gray-300)'"
        class="ui-mb-1 ui-mr-2"
      />
      <span
        class="c-text-b2 ui-transition ui-duration-300 ui-whitespace-no-wrap"
        :class="isActive ? 'c-text-primary-300': 'c-text-gray-300'"
      >
        {{ tab.label }}
      </span>
    </a>
  </router-link>
</template>

<script>
import { CIcon, Icon } from '@estrategiahq/coruja-web-ui';

export default {
  name: 'Tab',
  components: {
    CIcon,
    Icon,
  },
  props: {
    tab: { type: Object, required: true },
  },
};
</script>

<style scoped>
.active {
  box-shadow: inset 0 -0.25rem 0 -0.125rem var(--color-primary-300);
}
</style>
