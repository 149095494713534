<template>
  <div class="ui-absolute ui-overflow-hidden ui-w-full ui-h-full c-bg-primary-200 ui-bottom-0">
    <svg
      preserveAspectRatio="xMidYMid slice"
      width="100%"
      height="184"
      viewBox="0 0 1424 184"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.16"
        d="M1103.5 250C926.493 250 783 106.507 783 -70.5C783 -247.507 926.493 -391 1103.5 -391C1280.51 -391 1424 -247.507 1424 -70.5C1424 106.507 1280.51 250 1103.5 250Z"
        fill="#2B82D9"
      />
      <path
        opacity="0.24"
        d="M880 268C840.235 268 808 235.765 808 196C808 156.235 840.235 124 880 124C919.765 124 952 156.235 952 196C952 235.765 919.765 268 880 268Z"
        fill="#2B82D9"
      />
      <path
        opacity="0.24"
        d="M129.5 276C28.1557 276 -54 193.844 -54 92.5C-54 -8.84425 28.1557 -91 129.5 -91C230.844 -91 313 -8.84425 313 92.5C313 193.844 230.844 276 129.5 276Z"
        fill="#2B82D9"
      />
      <path
        opacity="0.24"
        d="M322 164C283.34 164 252 132.66 252 94C252 55.3401 283.34 24 322 24C360.66 24 392 55.3401 392 94C392 132.66 360.66 164 322 164Z"
        fill="#2B82D9"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'BgBall',
};
</script>
