<template>
  <div>
    <!-- Mobile -->
    <div class="c-col sm:c-d-none ui-h-full c-d-flex ui-items-center ui-absolute ui-left-0 ui-top-0">
      <button @click="openMenuMobile = true">
        <icon
          name="Hamburguer"
          color="var(--c-color-gray)"
        />
      </button>
    </div>
    <c-menu-mobile
      v-model="openMenuMobile"
      class="sm:c-d-none"
    />

    <!-- Desktop Dropdown -->
    <div class="c-d-none sm:c-d-flex ui-self-center ui-h-full">
      <div
        class="c-d-flex ui-space-x-4"
        v-if="loading"
      >
        <div class="c-d-flex ui-flex-col ui-items-end ui-justify-center ui-space-y-2">
          <div class="c-skeleton ui-w-16 ui-h-2 ui-rounded-full" />
          <div class="c-skeleton ui-w-24 ui-h-3 ui-rounded-full" />
        </div>
        <div class="c-skeleton ui-self-center ui-w-8 ui-h-8 ui-rounded-full" />
      </div>

      <c-dropdown-action
        placement="right-bottom"
        v-else
        class="ui-pr-0"
      >
        <template slot="label">
          <div class="c-d-flex-row">
            <div class="c-text-b3 c-text-gray-300 ui-text-right">
              {{ greeting }},
            </div>
            <div class="c-text-primary-400 c-text-b2">
              {{ user.name }}
            </div>
          </div>
          <icon
            name="arrow-down"
            class="ui-mx-2"
            color="gray"
          />
          <div class="ui-mb-4 sm:ui-mb-0 ui-w-8 ui-h-8">
            <p-avatar
              :loading="loading"
              :url="user.avatarUrl"
              :name="user.name"
            />
          </div>
        </template>
        <template slot="default">
          <c-options
            v-for="vertical of verticals"
            :key="vertical.id"
            class="hover-gray no-padding"
          >
            <a
              :href="getVerticalUrl(vertical)"
              class="c-d-flex ui-items-center ui-h-full ui-px-4 ui-py-2"
            >
              <icon
                class="ui-inline-block ui-mr-2"
                name="Favicon"
                :color="vertical.color"
              />
              <span
                class="c-text-gray-500"
                v-html="vertical.title"
              />
            </a>
          </c-options>
          <c-options class="hover-gray no-padding">
            <router-link
              :to="{ name: 'logout' }"
              class="c-d-flex ui-items-center ui-h-full ui-px-4 ui-py-2"
              data-cy="logoutLink"
            >
              <icon
                class="ui-inline-block ui-mr-2"
                name="Power"
              />
              <span class="c-text-gray-500">Sair</span>
            </router-link>
          </c-options>
        </template>
      </c-dropdown-action>
    </div>
  </div>
</template>

<script>
import { CDropdownAction, COptions, Icon } from '@estrategiahq/coruja-web-ui';
import CMenuMobile from '@/components/c-menu-mobile';
import { User } from '@/models/user';
import PAvatar from '@/components/p-avatar';
import { getToken } from '@/services/token';

export default {
  name: 'PMenu',
  components: {
    CDropdownAction,
    COptions,
    Icon,
    CMenuMobile,
    PAvatar,
  },
  props: {
    user: {
      type: User,
      required: true,
    },
    loading: Boolean,
  },
  data() {
    return {
      openMenuMobile: false,
      verticals: this.$vertical.getEnabled(),
    };
  },
  computed: {
    greeting() {
      const hrs = new Date().getHours();
      switch (true) {
      case (hrs < 6):
        return 'Boa noite';
      case (hrs < 12):
        return 'Bom dia';
      case (hrs < 18):
        return 'Boa tarde';
      default:
        return 'Boa noite';
      }
    },
  },
  methods: {
    getVerticalUrl(vertical) {
      const token = getToken();
      return typeof vertical.url === 'function' ? vertical.url({ token }) : vertical.url;
    },
  },
};
</script>

<style scoped>
.hover-gray:hover {
  background-color: var(--c-color-gray-100);
  border-bottom: 0.063rem solid var(--option-border);
}

.no-padding {
  padding: 0;
}
</style>
