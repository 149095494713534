<template>
  <div
    id="tab-container"
    class="c-container c-bg-white"
    :class="{
      'c-skeleton': loading,
      'ui-opacity-32': loading,
    }"
  >
    <div
      class="c-row c-horizontal-center ui-h-full"
      v-if="!loading"
    >
      <div class="c-col-2 sm:c-col-6 md:c-col-12 lg:c-col-10 ui-h-full">
        <div class="c-d-flex ui-flex-row ui-flex-no-wrap ui-h-full ui-overflow-auto">
          <template v-if="!isVisitorView">
            <tab
              v-for="(tab, index) in tabs"
              :key="`tabs-${index}`"
              :tab="tab"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tab from './tab';

export default {
  name: 'CTabs',
  components: { Tab },
  props: {
    tabs: { type: Array, required: true },
    loading: { type: Boolean, required: true },
    isVisitorView: { type: Boolean, required: true },
  },
};
</script>

<style scoped lang="scss">
#tab-container {
  box-shadow: 0 1px rgba(204, 204, 204, 0.4);
  height: 3.5rem;
}

@include c-mq(sm, 'max-width') {
  #tab-container {
    overflow-x: hidden;
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
