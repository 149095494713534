<template>
  <div
    class="ui-relative c-text-white"
    id="hero-container"
  >
    <!-- Background and balls -->
    <bg-balls />

    <!-- Foto e título -->
    <div class="c-container ui-relative ui-h-full">
      <div class="c-row ui-h-full c-horizontal-center">
        <!-- Título -->
        <div class="c-col-full sm:c-col md:c-col-8 lg:c-col-6 sm:ui-items-center c-d-flex ui-flex-wrap sm:ui-flex-no-wrap ui--mt-4 sm:ui-mt-0">
          <!-- Foto -->
          <div class="ui-w-full sm:ui-w-auto sm:ui-mr-6 c-d-flex sm:c-d-block ui-justify-center ui-mb-2">
            <div
              class="c-border-white ui-rounded-full ui-relative ui-cursor-pointer"
              :style="{ boxShadow: `0 0 0 4px ${badgeColor}` }"
              id="avatar-container"
              @click="onTrocarFotoButtonClick"
            >
              <p-avatar
                :loading="loading"
                :url="user.avatarUrl"
                :name="user.name"
                font-size="20px"
              />
            </div>

            <!-- Change photo button -->
            <div
              class="sm:ui-relative"
              v-if="!isVisitorView"
            >
              <div
                v-if="loading"
                class="ui-mt-6 sm:ui-mt-2 ui-mr-4 sm:ui-mr-2 ui-right-0 c-d-flex ui-absolute"
              >
                <div class="c-skeleton ui-h-4 ui-rounded-full ui-opacity-32 ui-w-4 ui-mr-1" />
                <div class="c-skeleton ui-h-4 ui-rounded-full ui-opacity-32 ui-w-20" />
              </div>
              <c-link
                v-else
                class="ui-mt-4 sm:ui-mt-0 ui-mr-2 sm:ui-mr-0 ui-right-0 c-d-flex c-text-white ui-whitespace-no-wrap ui-absolute"
                @click.prevent="onTrocarFotoButtonClick"
              >
                <icon
                  name="Edit"
                  color="white"
                  class="ui-flex-shrink-0"
                />
                Trocar foto
              </c-link>
            </div>
          </div>

          <!-- Textos -->
          <div class="ui-w-full sm:ui-w-auto ui-text-center sm:ui-text-left ui-mb-2 sm:ui-mb-0">
            <div class="ui-mb-2 md:ui-mb-4">
              <div
                v-if="loading"
                class="c-d-flex c-horizontal-center sm:ui-flex-col ui-space-y-2 c-vertical-end sm:c-vertical-start"
              >
                <div class="c-skeleton ui-h-4 ui-px-2 ui-rounded-full ui-mr-1 sm:ui-mb-1 ui-w-10 ui-h-4" />
                <div class="c-skeleton ui-h-4 ui-px-2 ui-rounded-full ui-w-48 ui-h-5" />
              </div>
              <div
                v-else
                class="c-d-flex c-horizontal-center sm:ui-flex-col"
              >
                <span class="c-text-b2 ui-mr-1">Olá,</span>
                <div class="ui-inline-flex c-vertical-start">
                  <img
                    v-if="hasBadge"
                    :src="'data:image/svg+xml;base64,' + user.badge.icon"
                    class="sm:ui-mt-1"
                  >
                  <span class="ui-ml-1 c-text-h3">
                    {{ user.name }}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="c-d-flex c-center sm:c-horizontal-start ui-flex-wrap"
            >
              <template v-if="loading">
                <div
                  v-for="n in [1,3,2]"
                  :key="n"
                  class="c-skeleton ui-h-4 ui-px-2 ui-rounded-full ui-mr-1 ui-mb-1 ui-inline-flex"
                  :class="`ui-w-${n*8}`"
                />
              </template>

              <c-tag
                v-else
                v-for="interest of user.interests"
                :key="interest.interestId"
                class="ui-mr-1 ui-mb-1 ui-inline-flex ui-whitespace-no-wrap"
                :text-color="interest.color"
                background-color="#FFF"
              >
                {{ interest.name }}
              </c-tag>
            </div>
          </div>
        </div>

        <!-- Ilustração Hero -->
        <div class="c-d-none sm:c-d-flex c-col-full sm:c-col-2 md:c-col-4 lg:c-col-4 ui-relative clip-right">
          <img
            id="hero-illustration"
            class="ui-absolute ui-left-0 ui-bottom-0"
            src="@/assets/images/hero-student-illustration.svg"
            alt="hero"
            style="max-width: unset; height: 105%;"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { User } from '@/models/user';
import BgBalls from '@/layouts/profile/hero/bg-balls';
import TrocarFotoModal from '@/modals/trocar-foto';
import PAvatar from '@/components/p-avatar';
import { Icon, CTag, CLink } from '@estrategiahq/coruja-web-ui';

export default {
  components: {
    BgBalls,
    PAvatar,
    Icon,
    CTag,
    CLink,
  },
  props: {
    user: {
      type: User,
      required: true,
    },
    loading: Boolean,
    isVisitorView: Boolean,
  },
  computed: {
    hasBadge() {
      return !!this.user.badge?.id;
    },
    badgeColor() {
      return this.hasBadge ? this.user.badge.color : 'var(--c-color-primary-400)';
    },
  },
  methods: {
    async onTrocarFotoButtonClick() {
      const file = await this.loadPicture();
      this.$modal.show({
        component: TrocarFotoModal,
        overlayOpacity: 0.88,
        props: { file },
      });
    },
    loadPicture() {
      return new Promise((resolve, reject) => {
        const fileInput = document.createElement('input');
        fileInput.accept = 'image/*';
        fileInput.type = 'file';
        fileInput.onchange = (event) => {
          const file = event.target['files']?.[0];
          return file ? resolve(file) : reject();
        };
        fileInput.click();
      });
    },
  },
};
</script>

<style scoped lang="scss">
#avatar-container {
  border-width: 2px;
  height: 72px;
  width: 72px;
}

#hero-container {
  min-height: 156px;
}

.clip-right {
  overflow-x: clip;
}

@include c-mq(sm) {
  #avatar-container {
    height: 100px;
    width: 100px;
  }

  #hero-container {
    height: 184px;
  }
}

@include c-mq(md) {
  .clip-right {
    overflow-x: unset;
  }
}

@include c-mq(lg) {
  #hero-illustration {
    left: unset;
    right: 0;
  }
}
</style>
