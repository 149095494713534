<template>
  <div>
    <div
      class="c-container"
      id="header-container"
    >
      <div class="c-row ui-h-full ui-relative">
        <div class="c-col-full c-d-flex ui-justify-center sm:ui-justify-between c-vertical-center">
          <estrategia-logo color="blue" />

          <p-menu
            :user="user"
            :loading="loading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { User } from '@/models/user';
import PMenu from '@/components/p-menu';
import EstrategiaLogo from '@/components/estrategia-logo';

export default {
  name: 'HeaderProfile',
  components: {
    EstrategiaLogo,
    PMenu,
  },
  props: {
    user: {
      type: User,
      required: true,
    },
    loading: Boolean,
  },
};
</script>

<style lang="scss" scoped>
#header-container {
  height: 96px;
}

@include c-mq(sm) {
  #header-container {
    height: 104px;
  }
}
</style>
