<template>
  <div class="c-container ui-w-full ui-h-full">
    <div class="c-row c-center ui-h-full">
      <div class="c-col-full sm:c-col-6 md:c-col-8">
        <div class="modal-box ui-rounded c-bg-white ui-relative ui-p-4 sm:ui-p-8 c-d-flex ui-flex-col">
          <!-- Botão Fechar -->
          <div
            class="ui-absolute ui-cursor-pointer"
            style="right: 24px; top: 24px;"
            @click="$modal.hide()"
          >
            <icon name="close" />
          </div>

          <!-- Título / Subtítulo -->
          <div>
            <p class="c-text-subtitle ui-mt-8 sm:ui-mt-0">
              Foto de perfil
            </p>
            <p class="c-text-b2 ui-mb-4">
              Arraste a foto para centralizar a imagem ou use os quadrados para reposicioná-la.
            </p>
          </div>

          <!-- Cortando foto -->
          <div class="ui-flex-1 ui-min-h-0 ui-flex ui-flex-col ui-w-full rounded">
            <img
              ref="image"
              :src="src"
              class="ui-max-w-full"
            >
          </div>

          <!-- Botão Salvar foto -->
          <div class="c-row ui-mt-6 c-horizontal-center">
            <div class="c-col-1/2 sm:c-col-2">
              <c-button
                outline
                class="ui-w-full"
                @click="$modal.hide()"
              >
                Cancelar
              </c-button>
            </div>

            <div class="c-col-1/2 sm:c-col-2">
              <c-button
                class="ui-w-full"
                @click="onSalvarClick"
                :disabled="uploadingImage"
              >
                {{ textoBotaoSalvar }}
              </c-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cropper from 'cropperjs';
import { mapGetters } from 'vuex';
import { CButton, Icon } from '@estrategiahq/coruja-web-ui';
import GenericModal from '@/modals/generic';
import { UserStoreKeys } from '@/store/modules/user';

export default {
  components: {
    CButton,
    Icon,
  },
  data: () => ({
    src: '',
    cropper: null,
  }),
  computed: {
    textoBotaoSalvar() {
      return this.uploadingImage ? 'Carregando...' : 'Salvar foto';
    },
    ...mapGetters({
      uploadingImage: UserStoreKeys.GET_REQUEST_LOADING,
    }),
  },
  props: {
    file: { type: Blob, required: true },
  },
  async mounted() {
    this.src = await getFileDataUrl(this.file);

    await this.$nextTick();

    this.cropper = new Cropper(this.$refs.image, {
      aspectRatio: 1,
      dragMode: 'move',
      viewMode: 1,
    });
  },
  methods: {
    async onSalvarClick() {
      const modalProps = {
        title: 'Houve um erro ao trocar a sua foto',
        subTitle: 'Para incluir uma nova foto, vai ser preciso fazer o fluxo novamente.',
        type: 'error',
        confirmButton: {
          label: 'Fechar',
          onClick: () => this.$modal.hide(),
        },
      };

      try {
        const image = await getCanvasBlob(this.cropper.getCroppedCanvas());
        await this.$store.dispatch(UserStoreKeys.ACTION_UPDATE_LOGGED_USER_PHOTO, image);

        modalProps.title = 'Foto alterada';
        modalProps.subTitle = 'Sua nova foto vai aparecer na Área do aluno, no app e aqui no seu Perfil.';
        modalProps.type = 'success';
      } finally {
        this.$modal.hide();
        this.$modal.show({
          component: GenericModal,
          overlayOpacity: 0.88,
          props: modalProps,
        });
      }
    },
  },
};

const getFileDataUrl = (file) => new Promise(resolve => {
  const reader = new FileReader();
  reader.onload = (e) => resolve(e.target.result);
  reader.readAsDataURL(file);
});

const getCanvasBlob = (canvas) => new Promise(resolve => canvas.toBlob(b => resolve(b)));
</script>

<style scoped>
@import '~cropperjs/dist/cropper.min.css';

.modal-box {
  height: calc(100vh - 64px);
}

@media (min-width: 768px) {
  .modal-box {
    height: 568px;
  }
}
</style>

<style>
.rounded .cropper-view-box,
.rounded .cropper-face {
  border-radius: 50%;
}
</style>
