<template>
  <div
    v-if="value"
    class="c-d-flex ui-flex-col c-bg-white ui-fixed ui-top-0 ui-left-0 ui-w-screen ui-h-screen c-z-modal"
  >
    <div class="c-d-flex ui-justify-between ui-items-start">
      <button
        class="focus:ui-outline-none c-text-primary-300 ui-px-4 ui-py-8"
        @click="close()"
      >
        <icon name="Close" />
      </button>
    </div>

    <div class="c-d-flex ui-h-screen ui-flex-col ui-w-full ui-overflow-auto">
      <ul class="divider-menu">
        <li
          class="c-d-flex ui-items-center"
          v-for="vertical of verticals"
          :key="vertical.id"
        >
          <a
            :href="getVerticalUrl(vertical)"
            class="ui-w-full ui-h-full ui-p-4"
          >
            <icon
              class="ui-inline-block ui-mr-2"
              name="Favicon"
              :color="vertical.color"
            />
            <span
              class="c-text-gray-500"
              v-html="vertical.title"
            />
          </a>
        </li>
        <li class="active-light">
          <router-link
            :to="{ name: 'logout' }"
            class="c-d-flex ui-items-center ui-p-4"
          >
            <icon
              class="ui-inline-block ui-mr-2"
              name="Power"
            />
            <span class="c-text-gray-500">Sair</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Icon } from '@estrategiahq/coruja-web-ui';
import { getToken } from '@/services/token';

export default {
  components: {
    Icon,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      verticals: this.$vertical.getEnabled(),
    };
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
    getVerticalUrl(vertical) {
      const token = getToken();
      return typeof vertical.url === 'function' ? vertical.url({ token }) : vertical.url;
    },
  },
};
</script>

<style scoped>
.divider-menu {
  border-top: 1px;
  border-bottom: 1px;
  border-style: solid;
  border-color: var(--c-color-gray-200);
}

.divider-menu > li:not(:last-child) {
  border-bottom: 1px;
  border-style: solid;
  border-color: var(--c-color-gray-200);
}
</style>
